<template>
<div >
    <div class="login-div" style="background-size:cover;   min-height:100vh;  padding-bottom: 50px;
}">

        <div class="row">
            <div class="col-lg-12 col-12">
                   <div style="background:#fff; padding:20px; max-width:500px; margin:0 auto; margin-top:50px;">
                  <div style="margin:0 auto; min-width:100%;display: flex;
    justify-content: center;">

                 <img src="../../assets/images/logo.png" height="90" class="mt-4" >

                    </div>
     <div class="form-div " style="min-height:60vh">
                    <form action class="form">
                        <h4 class="mt-4 head-font">{{tran.changePin}}</h4>

<p class="pb-4 pt-4">
    A 6 digit token has been sent via SMS to your registered phone number (<b>ending in -{{phoneLast}})</b>. kindly type the token here and provide a new PIN for your reQuid account.
</p>
                        <div class="form-group">
                            <label class="form-label" for="first" @click.prevent="focusOnInput($event)">SMS Token</label>
                            <div class="eye-div">
                                <input
                    v-model="smsToken"
                    class="form-input"
                    type="text"
                    autocomplete="off"
                    readonly onfocus="this.removeAttribute('readonly');"
                  @keyup="checkEnterButton" >
                             
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="form-label" for="first" @click.prevent="focusOnInput($event)">{{tran.new_pin}}</label>
                            <div class="eye-div">
                                <input
                    v-model="newPin"
                    class="form-input"
                    :type="hideInput"
                    autocomplete="off"
                  @keyup="checkEnterButton" maxlength="4">
   <i class="fa fa-eye-slash" @click="showInputText" v-if="showEye"></i>
                                <i class="fa fa-eye" @click="hideInputText" v-if="hideEye"></i>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="last">{{tran.retype_new_pin}}</label>
                                <input class="form-input"   v-model="retypeNewPin" :type="hideInput" autocomplete="off" @keyup="checkEnterButton" maxlength="4">
              </div>

                                <div class="form-group">
                                    <button
                  type="button"
                  class="submit-btn mt-2"
                  :class="{'fade-elem': loader}"
                  @click.prevent="changePin">
                  {{ loader && !resendSmsState ? '' : tran.save }}
                  <span class="loader mt-1" v-if="loader"></span>
                </button>
                                </div>
                    </form>
                    <p class="text-center">You didn't get the SMS token? <span style="text-decoration:underline; cursor:pointer" @click="resendSms">Resend SMS  <i class="fa fa-spin fa-spinner" style="color:#000" v-if="resendSmsState" ></i></span></p>
                </div>

            </div>
           
        </div>
        </div>
    </div>
</div>
</template>

<script>
import trans from "../../../translations";
import {
    mapState
} from "vuex";
import axios from "axios"
import http from "../../http/index";
import api from "../../apis/index";
import {
    userNotification
} from "../dashboard/mixins/notifyUser.js";
import {
    setTimeout
} from 'timers';
export default {

    mixins: [userNotification],
    data() {
        return {

            tran: {},
            hideEye: false,
            showEye: true,
            hideInput: "password",
            oldPin: '',
            newPin: '',
            retypeNewPin: '',
            smsToken:'',
            phoneLast:'',
            resendSmsState:false
        };
    },
    computed: {
        ...mapState({

            formdata: state => state.auth.updatePasswordData,

            loader: state => state.auth.loader,

            token: state => state.auth.token,

            userProfileInfo: state => state.auth.userProfileInfo,

        })
    },
    methods: {
        
        resetPin() {
            this.$router.push('/reset/pin')
        },

      
  resendSms() {
           let resetPinQuery =  localStorage.getItem('activatorQuery')
resetPinQuery = JSON.parse(resetPinQuery)
            let vm = this
        this.resendSmsState = true
            http.post(api.verifyEmailToken, resetPinQuery)
                .then(response => {
    vm.resendSmsState = false
                

                 
                })
                .catch(error => {
                  vm.resendSmsState = false
                    this.$helpers.log(error.response);
                    vm.errorMessage = error.response.data.message
                     this.$helpers.log(error.response);
                            if (error.response !== undefined) {
                                this.$toast.warn({
                                    title: this.tran.sorry,
                                    icon: require("../../assets/images/cautionicon.png"),
                                    timeOut: 8000,
                                    message: error.response.data.message,
                                    position: "top center"
                                });
                            }
                });

        },
        changePin() {
if(this.newPin !== this.retypeNewPin){

    this.$toast.warn({
        timeOut: 5000,
        icon: require("../../assets/images/cautionicon.png"),
        message:
         'Please the new PIN and retype new PIN field should be the same',
        position: "top center"
      });
      return
}
            let apiData = {
                sms_token: this.smsToken,
                pin: this.newPin
            }
          let authObject =  localStorage.getItem('resetPinObject')
authObject = JSON.parse(authObject)
            this.$store.commit("auth/startLoader");
            axios.patch('https://staging-api.requid.com/'+api.resetPin, apiData,{

                headers: {
    Authorization: "Bearer " +  authObject.authToken,
   
  },


            })
                .then(response => {
                    this.$helpers.log(response);
                     this.$store.commit("auth/stopLoader");
                    this.$toast.success({
                        title: this.tran.success,
                        icon: require("../../assets/images/successicon.png"),
                        timeOut: 5000,
                        message: "Pin changed successfully",
                        position: "top center"
                    });
 localStorage.removeItem('resetPinObject')
 localStorage.removeItem('activatorQuery')
                    this.$router.push('/profile')
                })
                .catch(error => {
                    this.$store.commit("auth/stopLoader");
                    this.$helpers.log(error.response);
                    if (error.response !== undefined) {
                        this.$toast.warn({
                            title: this.tran.sorry,
                            icon: require("../../assets/images/cautionicon.png"),
                            timeOut: 5000,
                            message: error.response.data.message,
                            position: "top center"
                        });
                    }

                });
        },
        showInputText() {

            this.hideInput = "text";
            this.showEye = false;
            this.hideEye = true;

        },
        hideInputText() {

            this.hideInput = "password";
            this.showEye = true;
            this.hideEye = false;

        },

        focusOnInput(e) {
            e.target.parentNode.querySelector('.form-input').focus();
        },

        checkEnterButton() {
            if (event.keyCode === 13) {
                this.updatePassword();
            }
        },
    },

    mounted() {
        this.tran = trans;
this.phoneLast = this.$route.query.phone_last_2d
        // setTimeout(() => {

        //     if (this.token == null || this.userProfileInfo.has_pin !== true) {

        //         this.$router.push('/profile')
        //     }
        // }, 900)
      this.$store.commit('auth/stopLoader')
    },
    beforeCreate() {
        if (this.token !== null) {

            this.$store.dispatch('auth/getUserProfile');

        }
    }
};
</script>

<style>
</style>
