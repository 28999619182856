<template>
    <ChangePin/>
</template>

<script>
import ChangePin from '../../components/auth/ChangePin'
export default {

    components: {
        ChangePin
    }
}
</script>

<style>

</style>
